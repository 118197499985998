import "primereact/resources/themes/lara-light-indigo/theme.css";    
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
// import './CallsTable.css'
import React, { useState, useEffect,useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import axios from '../../Utils/axios'
import { allAppointmentsUrl, appointmentOutcomeUrl } from "../../Utils/Urls";
import { useParams } from 'react-router-dom';

export default function AppointmentsTable() {
    const {locationId} = useParams()
    const url = `${allAppointmentsUrl}${locationId}`
    const [customers, setCustomers] = useState(null);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [visible, setVisible] = useState(false);
    const [outcome, setOutcome] = useState('');
    const [outcomeValue, setOutcomeValue] = useState('');
    const [notes, setNotes] = useState('');
    const [isMounted, setIsMounted] = useState(false)
    const [id, setId] = useState('')
    const iconsAndText = [
        { icon: 'pi pi-check-circle', text: 'Sale', value: 'showed', color:'green'},
        { icon: 'pi pi-times', text: 'No Sale', value: 'showed', color:'yellow' },
        { icon: 'pi pi-hourglass', text: 'Trial', value: 'showed', color:'purple' },
        { icon: 'pi pi-calendar-times', text: 'Did Not Show', value: 'noshow', color:'red' },
        // { icon: 'pi pi-hourglass', text: 'Said they are still thinking', value: 'showed' },
        // { icon: 'pi pi-question-circle', text: 'Something else', value: 'showed' },
        // { icon: 'pi pi-clock', text: 'Started a trial', value: 'showed' },
      ];
    const toast = useRef(null);

      const handleCloseDialog = () =>{
        setVisible(false)
        setOutcome('')
        setNotes('')
        setOutcomeValue('')
      }

    useEffect(()=>{

        axios.get(url, {
            headers: {'Content-Type': 'multipart/form-data'},
          })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setCustomers(getCustomers(response.data));
                    setLoading(false);
                    initFilters();
                  }
                })
            .catch((error)=>{
                setCustomers(null)
                
            })
    },[url, isMounted])

    const getCustomers = (data) => {
        return [...(data || [])].map((d) => {
            d.appointment_booked = new Date(d.appointment_booked);
            d.appointmentbooked_for = new Date(d.appointmentbooked_for);
            return d;
        });
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        });
      };

    const formatDateTime = (value) => {
        const formattedDate = formatDate(value);
        const formattedTime = value.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        });
        return `${formattedDate}    ${formattedTime}`;
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            Calendar_Name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            phone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            first_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            last_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            outcome: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            appointment_booked: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            appointmentbooked_for: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }
        });
        setGlobalFilterValue('');
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };

    const dateBookedOnBodyTemplate = (rowData) => {
        const db_outcome = rowData.outcome
        return( 
            <div className={`outcome-${db_outcome}`}>
            {formatDate(rowData.appointment_booked)}
            </div>
        )
    };

    const dateBookedAtBodyTemplate = (rowData) => {
        const db_outcome = rowData.outcome
        return(
            <div className={`outcome-${db_outcome}`}>
            {formatDateTime(rowData.appointmentbooked_for)}
            </div>
        )
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    const handleopendialog = (id)=>{
        setId(id)
        setVisible(true)
    }

    const handleOutcomeSubmit = () =>{

        if(outcome !== ''){
            const updateUrl = `${appointmentOutcomeUrl}${id}`
            const data = {
                outcome,
                notes,
                outcomeValue
            }
            axios.post(updateUrl, data, {
                headers: {'Content-Type': 'multipart/form-data' },
            })
            .then((response) => {
                if (response.status === 200) {
                    setVisible(false)
                    setOutcome('')
                    setNotes('')
                    setOutcomeValue('')
                    setIsMounted(!isMounted)
                    toast.current.show({severity:'success', summary: 'Success', detail:'Record Submitted', life: 2000});
                }
                else{
                    toast.current.show({severity:'error', summary: 'Error', detail:response.data, life: 2000});
                }
                })
            .catch((error)=>{
                toast.current.show({severity:'error', summary: 'Error', detail:error.response.data, life: 2000});
            })
        }
        else{
            toast.current.show({severity:'warn', summary: 'Warning', detail:'Please select an outcome', life: 2500});
        }
    }

    const outcomeBodyTemplate = (rowData) => {
        const db_outcome = rowData.outcome

        return (
        <div className="flex justify-content-center">
        <Toast ref={toast} />
        <Button className="set-outcome-button" icon="pi pi-ellipsis-h" onClick={() => handleopendialog(rowData.appointment_id)}/>
        <Dialog header="HOW DID THE APPOINTMENT GO?" className="outcome-dialog" visible={visible} onHide={() => handleCloseDialog()}>
        <div className="modal-content">
        {iconsAndText.map((item, index) => (
            <div className={`rectangle-box-${item.color}`} key={item.text} onClick={() => {setOutcome(item.text); setOutcomeValue(item.value)}}>
              <div className={`box-logo-${item.color}`}>
                <span className={`pi ${item.icon} outcome-icon`}></span>
              </div>
              <div className={`box-content ${outcome === item.text ? `box-content-selected-${item.color}` : ''}`}>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        <div className="callNotes-container">
            <h3 className="callNotes-heading">Appointment notes</h3>
            <InputTextarea placeholder="Any notes about this appointment?" value={notes} onChange={(e) => setNotes(e.target.value)} rows={5} cols={30} style={{width:'100%'}}/>
        </div>
        <div className="submit-buttons-container">
        <Button className="cancel-button" label="Cancel" onClick={() => handleCloseDialog()} />
        <Button className="record-button" label="Update Outcome" icon="pi pi-phone" onClick={handleOutcomeSubmit}/>
        </div>
        </div>
        </Dialog>
        </div>
        )};

    const redirectBody = (rowData) => {

        return(
            <div className="flex justify-content-center">
                <Button className="set-redirect-button" icon="pi pi-external-link" onClick={() => handleRedirect(rowData.contact_id)}/>
            </div>
        )
    }
    

    const handleRedirect = (contactId) => {
        // window.location.href = `https://app.supafitgrow.com/v2/location/${locationId}/contacts/detail/${contactId}`; 
        const url = `https://app.supafitgrow.com/v2/location/${locationId}/contacts/detail/${contactId}`;
        window.open(url, '_blank');
      };
    

    const outcomeBody = (rowData) => {
        const db_outcome = rowData.outcome

        return(
            <div className={`outcome-${db_outcome}`}>
                {db_outcome? <span>{db_outcome}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const firstNameBody = (rowData) => {
        const db_outcome = rowData.outcome
        const firstName = rowData.first_name
        return(
            <div className={`outcome-${db_outcome}`}>
                {firstName? <span>{firstName}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const lastNameBody = (rowData) => {
        const db_outcome = rowData.outcome
        const lastName = rowData.last_name
        return(
            <div className={`outcome-${db_outcome}`}>
                {lastName? <span>{lastName}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const emailBody = (rowData) => {
        const db_outcome = rowData.outcome
        const email = rowData.email
        return(
            <div className={`outcome-${db_outcome}`}>
                {email? <span>{email}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const phoneBody = (rowData) => {
        const db_outcome = rowData.outcome
        const phone = rowData.phone
        return(
            <div className={`outcome-${db_outcome}`}>
                {phone? <span>{phone}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const calendarNameBody = (rowData) => {
        const db_outcome = rowData.outcome
        const Calendar_Name = rowData.Calendar_Name
        return(
            <div className={`outcome-${db_outcome}`}>
                {Calendar_Name? <span>{Calendar_Name}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        const db_outcome = rowData.outcome
        return <div className={`outcome-${db_outcome}`}>{rowIndex}</div>;
      };

    const header = renderHeader();

    return (
        <div className="card">
            <DataTable value={customers} paginator showGridlines rows={10} loading={loading} dataKey="appointment_id" 
                    filters={filters} globalFilterFields={['email', 'phone', 'first_name', 'last_name', 'Calendar_Name', 'outcome', 'appointment_booked', 'appointmentbooked_for']} header={header}
                    emptyMessage="No appointments found.">
                <Column header="SR NO" className="padding-0" style={{ minWidth: '2rem'  }} body={srNoBodyTemplate} />
                <Column filterField="first_name" header="First Name" filter filterPlaceholder="Search by first name" className="padding-0" style={{ minWidth: '9rem' }} body={firstNameBody}/>
                <Column filterField="last_name" header="Last Name" filter filterPlaceholder="Search by last name" className="padding-0" style={{ minWidth: '9rem'  }} body={lastNameBody}/>
                <Column filterField="email" header="Email" filter filterPlaceholder="Search by from" className="padding-0" style={{ minWidth: '8rem'  }} body={emailBody}/>
                <Column filterField="phone" header="Phone" filter filterPlaceholder="Search by to" className="padding-0" style={{ minWidth: '12rem'  }} body={phoneBody}/>
                <Column header="Appointment Booked On" filterField="appointment_booked" dataType="date" className="padding-0" style={{ minWidth: '5rem' }} body={dateBookedOnBodyTemplate} filter filterElement={dateFilterTemplate} />
                <Column header="Appointment Timing" filterField="appointmentbooked_for" dataType="date" className="padding-0" style={{ width: '14rem' }} body={dateBookedAtBodyTemplate} filter filterElement={dateFilterTemplate} />
                <Column filterField="Calendar_Name" header="Calandar Name" filter filterPlaceholder="Search by to" className="padding-0" style={{ minWidth: '8rem'  }} body={calendarNameBody}/>                    
                <Column header="Outcome" filter filterField="outcome" showFilterMatchModes={false} filterMenuStyle={{ width: '9rem' }} className="padding-0" style={{ minWidth: '14rem'  }}
                body={outcomeBody}/>
                <Column field="status" header="Set Outcome" filterMenuStyle={{ width: '14rem' }} style={{ width: '3rem' }} body={outcomeBodyTemplate}/>
                <Column header="Open in CRM" filterMenuStyle={{ width: '14rem' }} style={{ width: '3rem' }} body={redirectBody}/>
            </DataTable>
        </div>
    );
}
