import React from 'react';

const AudioPlayer = ({url}) => {
  
  return (
    <div>
        <audio src={url} controls />
    </div>
  );
};

export default AudioPlayer;