import "primereact/resources/themes/lara-light-indigo/theme.css";    
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import './CallsTable.css'
import React, { useState, useEffect,useRef } from 'react';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from 'primereact/calendar';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import AudioPlayer from "./Audio";
import axios from '../../Utils/axios'
import { allCallsUrl, submitOutcomeUrl } from "../../Utils/Urls";
import { useParams } from 'react-router-dom';

export default function CallsTable() {
    const {locationId} = useParams()
    const url = `${allCallsUrl}${locationId}`
    const [customers, setCustomers] = useState(null);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [visible, setVisible] = useState(false);
    const [outcome, setOutcome] = useState('')
    const [notes, setNotes] = useState('');
    const [isMounted, setIsMounted] = useState(false)
    const [id, setId] = useState('')
    const iconsAndText = [
        { icon: 'pi pi-phone', text: 'No answer' },
        { icon: 'pi pi-file-edit', text: 'Left a message' },
        { icon: 'pi pi-times-circle', text: 'Not interested' },
        { icon: 'pi pi-calendar-plus', text: 'Booked an appointment' },
        { icon: 'pi pi-hourglass', text: 'Said they are still thinking' },
        { icon: 'pi pi-question-circle', text: 'Something else' },
        { icon: 'pi pi-clock', text: 'Started a trial' },
        { icon: 'pi pi-check-circle', text: 'They signed up' }
      ];
    const toast = useRef(null);

      const handleCloseDialog = () =>{
        setVisible(false)
        setOutcome('')
        setNotes('')
      }

    useEffect(()=>{

        axios.get(url, {
            headers: {'Content-Type': 'multipart/form-data'},
          })
            .then((response) => {
                if (response.status === 200) {
                    setCustomers(getCustomers(response.data));
                    setLoading(false);
                    initFilters();
                  }
                })
            .catch((error)=>{
                setCustomers(null)
                
            })
    },[url, isMounted])

    const getCustomers = (data) => {
        return [...(data || [])].map((d) => {
            d.created_at = new Date(d.created_at);

            return d;
        });
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        });
      };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            _from: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            _to: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            direction: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            duration: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            first_time: { value: null, matchMode: FilterMatchMode.EQUALS },
            first_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            last_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            outcome: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            created_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalFilterValue('');
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };

    const callRecordingBodyTemplate = (rowData) => {
        const record = rowData.recording_url;

        return (
            <div className="flex align-items-center gap-2"> 
                {record ? <AudioPlayer url={record} /> : <i className="pi">N/A</i>} 
            </div>
        );
    };


    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.created_at);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    const handleopendialog = (id)=>{
        setId(id)
        setVisible(true)
    }

    const handleOutcomeSubmit = () =>{
        if(outcome !== ''){
            const updateUrl = `${submitOutcomeUrl}${id}`
            const data = {
                outcome,
                notes
            }
            axios.post(updateUrl, data, {
                headers: {'Content-Type': 'multipart/form-data' },
            })
            .then((response) => {

                if (response.status === 200) {
                    console.log('success outcome')
                    setVisible(false)
                    setOutcome('')
                    setNotes('')
                    setIsMounted(!isMounted)
                    toast.current.show({severity:'success', summary: 'Success', detail:'Record Submitted', life: 2000});
                }
                else{
                    toast.current.show({severity:'error', summary: 'Error', detail:response.data, life: 2000});
                    }
                })
            .catch((error)=>{
                toast.current.show({severity:'error', summary: 'Error', detail:error.response.data, life: 2000});
            })
        }
        else{
            toast.current.show({severity:'warn', summary: 'Warning', detail:'Please select an outcome', life: 2500});
        }
      }

    const outcomeBodyTemplate = (rowData) => {

        return (
        <div className="flex justify-content-center">
        <Toast ref={toast} />
        <Button className="set-outcome-button" icon="pi pi-ellipsis-h" onClick={() => handleopendialog(rowData.call_sid)}/>
        <Dialog header="HOW DID THE CALL GO?" className="outcome-dialog" visible={visible} onHide={() => handleCloseDialog()}>
        <div className="modal-content">
        {iconsAndText.map((item, index) => (
            <div className="rectangle-box" key={item.text} onClick={() => setOutcome(item.text)}>
              <div className="box-logo">
                <span className={`pi ${item.icon} outcome-icon`}></span>
              </div>
              <div className={`box-content ${outcome === item.text ? 'box-content-selected' : ''}`}>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        <div className="callNotes-container">
            <h3 className="callNotes-heading">Call notes</h3>
            <InputTextarea placeholder="Any notes about this call?" value={notes} onChange={(e) => setNotes(e.target.value)} rows={5} cols={30} style={{width:'100%'}}/>
        </div>
        <div className="submit-buttons-container">
        <Button className="cancel-button" label="Cancel" onClick={() => handleCloseDialog()} />
        <Button className="record-button" label="Update Outcome" icon="pi pi-phone" onClick={handleOutcomeSubmit}/>
        </div>
        </div>
        </Dialog>
        </div>
        )};

    const verifiedBodyTemplate = (rowData) => {
        const first_time = rowData.first_time
        return (
            <i
            className={classNames('pi', {
              'text-green-500 pi-check-circle': first_time === true,
              'text-red-500 pi-times-circle': first_time === false,
            })}
          >
            {first_time === null ? 'N/A' : ''}
          </i>
        );
      };

    const verifiedFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="verified-filter" className="font-bold">
                    First Time
                </label>
                <TriStateCheckbox inputId="verified-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };

    const redirectBody = (rowData) => {

        return(
            <div className="flex justify-content-center">
                <Button className="set-redirect-button" icon="pi pi-external-link" onClick={() => handleRedirect(rowData.ghl_contact_id)}/>
            </div>
        )
    }
    

    const handleRedirect = (contactId) => {
        const url = `https://app.supafitgrow.com/v2/location/${locationId}/contacts/detail/${contactId}`;
        window.open(url, '_blank'); 
      };

    const outcomeBody = (rowData) => {
        const db_outcome = rowData.outcome

        return(
            <span>
                {db_outcome? <span>{db_outcome}</span>:<i className="pi">N/A</i>}
            </span>
        )
    }

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        return <span>{rowIndex}</span>;
      };

    const header = renderHeader();

    return (
        <div className="card">
            <DataTable value={customers} paginator showGridlines rows={7} loading={loading} dataKey="call_sid" 
                    filters={filters} globalFilterFields={['_from', '_to', 'first_name', 'last_name', 'direction', 'duration', 'first_time', 'created_at', 'outcome']} header={header}
                    emptyMessage="No calls found.">
                <Column header="SR NO" style={{ minWidth: '2rem' }} body={srNoBodyTemplate} />
                <Column header="Date" filterField="created_at" dataType="date" style={{ minWidth: '6rem' }} body={dateBodyTemplate} filter filterElement={dateFilterTemplate} />
                <Column field="_from" header="From" filter filterPlaceholder="Search by from" style={{ minWidth: '8rem' }} />
                <Column field="_to" header="To" filter filterPlaceholder="Search by to" style={{ minWidth: '8rem' }} />
                <Column field="direction" header="Direction" filter filterPlaceholder="Search by direction" style={{ minWidth: '7rem' }} />
                <Column field="duration" header="Duration" filter filterPlaceholder="Search by duration" style={{ width: '3rem' }} />
                <Column field="first_name" header="First Name" filter filterPlaceholder="Search by first name" style={{ minWidth: '9rem' }} />
                <Column field="last_name" header="Last Name" filter filterPlaceholder="Search by last name" style={{ minWidth: '9rem' }} />
                <Column header="Call Recording" filterField="representative" showFilterMatchModes={false} filterMenuStyle={{ width: '10rem' }} style={{ minWidth: '14rem' }}
                    body={callRecordingBodyTemplate}/>
                    <Column field="first_time" header="First Time" dataType="boolean" bodyClassName="text-center" style={{ width: '4rem' }} body={verifiedBodyTemplate} filter filterElement={verifiedFilterTemplate} />
                    <Column header="Outcome" filter filterField="outcome" showFilterMatchModes={false} filterMenuStyle={{ width: '9rem' }} style={{ minWidth: '14rem' }}
                    body={outcomeBody}/>
                    <Column field="status" header="Set Outcome" filterMenuStyle={{ width: '14rem' }} style={{ width: '3rem' }} body={outcomeBodyTemplate}/>
                    <Column header="Open in CRM" filterMenuStyle={{ width: '14rem' }} style={{ width: '3rem' }} body={redirectBody}/>
            </DataTable>
        </div>
    );
}
